import { MixpanelClient, MixpanelEvent } from '@enaratech/funnel-helper';
import { Autocomplete, TextField } from '@mui/material';
import { FC } from 'react';
import { useRoutePath } from 'src/hooks/useRoutePath';

type PartnerTuple = {
  name: string;
  id: number;
};

type Props = {
  selectedPartner: PartnerTuple | null;
  availablePartners: PartnerTuple[];
  onChange: (value: PartnerTuple) => void;
};

const ReferralPartnerOptions: FC<Props> = ({ selectedPartner, availablePartners, onChange }) => {
  const routePath = useRoutePath();

  const trackMixpanelEvent = (field: string, event: MixpanelEvent, value: string) => {
    MixpanelClient.trackEvent({
      event,
      properties: {
        field,
        value,
        source: routePath,
      },
    });
  };

  return (
    <Autocomplete
      className='autocomplete'
      options={availablePartners}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      value={selectedPartner}
      renderInput={(params) => <TextField {...params} placeholder='Select one clinic' />}
      onChange={(_, value) => {
        if (value) {
          onChange(value);
          trackMixpanelEvent('Referral Clinic ID', MixpanelEvent.Select, `${value.id}`);
        }
      }}
    />
  );
};

export default ReferralPartnerOptions;
