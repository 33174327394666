import {
  AppointmentIntentionWithProviderCodes,
  AppointmentSpecialty,
  AvailableLanguages,
  getPartnerById,
  LeadSystemSource,
  ProgramType,
  scheduleClient,
  ScheduledAppointment,
  ScheduledAppointments,
  SchedulingWorkflow,
  Step,
  SupportedLocationType,
} from '@enaratech/funnel-helper';
import { Box, CircularProgress, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CalendarIcon from 'src/components/Common/Icons/CalendarIcon';
import NextSteps from 'src/components/Common/NextSteps/NextSteps';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth';
import { useClinic } from 'src/contexts/clinic/index';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { IPOPath, isIPOPath, navigateToPage } from 'src/pages/routes';
import { capitalizeToKebabCase } from 'src/utils/array';
import CongratsLogo from '../../../assets/images/congrats.png';
import './scss/ssbCongrats.scss';

export const SelfServeBookingResult: FC = () => {
  const [appointments, setAppointments] = useState<
    [AppointmentIntentionWithProviderCodes, ScheduledAppointment][] | null
  >(null);
  const [loading, setLoading] = useState<boolean | null>(true);

  const [steps, setSteps] = useState<Step[] | null>(null);
  const routePath = useRoutePath();
  const navigate = useNavigate();
  const { clinicState } = useClinic();
  const {
    authState: { user },
  } = useAuth();

  const findStepByParams = (steps: Step[] | null, appointmentCode: string) => {
    return steps?.find(({ config }) => {
      const { allowedIntention, allowedLanguages, allowedSpecialty, allowedLocations } = config;

      const [intention, specialty, location, language] = appointmentCode.split('-');

      return (
        intention === allowedIntention &&
        specialty === allowedSpecialty &&
        allowedLocations.includes(location as SupportedLocationType) &&
        allowedLanguages.includes(language.toLowerCase() as AvailableLanguages)
      );
    });
  };

  useEffect(() => {
    (async () => {
      const steps = await scheduleClient.getStepsForMember({
        clinicId: clinicState!.details.clinicId,
        memberId: user!.id,
        programType: user!.programType as ProgramType.InClinic | ProgramType.Remote,
        systemSource: capitalizeToKebabCase(user!.systemSource) as LeadSystemSource,
        schedulingWorkflow: SchedulingWorkflow.SelfServeBooking,
      });

      setSteps(steps);

      const scheduledAppointments = Object.fromEntries(
        Object.entries((await scheduleClient.getAppointmentsScheduledByUser()) ?? [])?.filter(
          ([key]) => !!findStepByParams(steps, key)
        )
      ) as unknown as ScheduledAppointments & {
        success?: true;
        MD?: [];
        DIA?: [];
        NS?: [];
      };

      if (scheduledAppointments) {
        const appointments = Object.entries(scheduledAppointments).map(([code, appointment]) => [
          code,
          appointment,
        ]) as [AppointmentIntentionWithProviderCodes, ScheduledAppointment][];

        setAppointments(appointments);

        setLoading(false);
      } else {
        Toast.notification('error', 'Could not get the list of appointments');
      }
    })();
  }, []);

  const getProviderDescription = (
    appointmentCode: AppointmentIntentionWithProviderCodes,
    calendarName: string
  ): string => {
    const specialty = appointmentCode.split('-')[1];

    const specialtyDescription =
      findStepByParams(steps, appointmentCode)?.details.description ?? '';

    if (('DIA' as AppointmentSpecialty) === specialty) {
      const {
        details: { clinicId, address },
      } = clinicState!;

      const partner = getPartnerById(clinicId, clinicState!.partners);

      return `In clinic ${specialtyDescription} at ${partner?.name}, ${address}`;
    }

    return `Online ${specialtyDescription} with ${calendarName}`;
  };

  const handleGoToHome = () => {
    navigateToPage({ targetPage: IPOPath.Welcome, navigate });
  };

  return (
    <BasicLayout
      title={`Congratulations, ${user?.firstName}! You successfully booked your appointments`}
      buttonProps={
        isIPOPath(routePath) ? { text: 'Go to Home', onClick: handleGoToHome } : undefined
      }>
      <Box className='logo-box'>
        <img src={CongratsLogo} alt={'Congrats Logo'} />
      </Box>
      <Box className='container-box'>
        {loading ? (
          <CircularProgress className='circular-progress-congratulations' />
        ) : (
          appointments
            ?.filter(([_, appointment]) => appointment)
            .map(([appointmentCode, appointment]) => (
              <Box className='appointment-box' key={appointmentCode}>
                <CalendarIcon />
                <Box className='date-time-box'>
                  <Typography variant={'subtitle1'}>
                    {getProviderDescription(appointmentCode, appointment.calendar)}
                  </Typography>
                  <Typography variant={'h6'}>
                    {`${DateTime.fromISO(appointment.datetime).toFormat('DDD @ hh:mm a')}`}
                  </Typography>
                </Box>
              </Box>
            ))
        )}
      </Box>

      <NextSteps isSSB={true} />
    </BasicLayout>
  );
};

export default withProgress(SelfServeBookingResult, Page.SelfServeBookingResult);
